.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #282c34 !important;
}

.App-base {
  padding-top: 55px;
  min-height: calc(100vh - 60px);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  text-align: center;
}

div.App-base > div:first-child {
  margin-top: 0 !important;
  max-width: 100% !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.time {
  font-weight: bold;
}


footer {
  color: #FFF !important;
}

.failed, .loading {
  color: #FFF;
  margin: auto;
}