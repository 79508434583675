.boss-stars, .boss-health {
    position: absolute;
    top: -10px;
    color: white !important;
    border-radius: 4px;
    padding: 3px;
    font-size: 20px !important;
}

.boss-stars {
    left: 4px;
}

.boss-health {
    right: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.boss-specials {
    position: absolute;
    left: 5px;
    top: 0px;
    font-size: 20pt;
}

.maxTable {
    border-collapse: collapse;
    font-size: 1.2rem
}

.maxTable thead tr th:first-child, .maxTable tbody tr td:first-child {
    border-right: 1px solid black;
}

.commentResult {
    color: rgba(0, 0, 0, 1) !important;
    font-size: 20pt;
}
.authorFlair {
    font-size: smaller;
    display: inline-block;
    position: relative;
}
.flair {
    border: 1px solid var(--base2);
    border-radius: 3pt;
    padding: 2pt;
}

.float {
    -webkit-box-shadow: 0px 9px 5px -3px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 9px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0px 9px 5px -3px rgba(0,0,0,0.2);
}

.resulttable {
    margin: auto;
    border-radius: 4px;
}

.resulttable {
  border: 1px solid black;
  border-collapse: collapse;
}

.resulttable th,.resulttable td {
    padding: 5px;
    font-size: 1rem;
}